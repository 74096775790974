<script lang="ts" context="module">
  import { instant } from "$utils/temporal";
  import { type Readable, writable, derived } from "svelte/store";

  interface Command {
    position: number;
    detections: boolean;
    images: number;
    cameras: number;
    system: number;
  }
  const now = instant({ seconds: 1 });
  const last = writable<Temporal.Instant | nullish>(null);
  const ago = derived<[typeof now, typeof last], Temporal.Duration | nullish>(
    [now, last],
    ([$now, $last]) => {
      if (!$now || !$last) return null;
      return $now.toInstant().since($last);
    }
  );
  const online = derived<typeof ago, boolean | nullish>(ago, ($ago) => {
    if (!$ago) return null;
    return (
      Temporal.Duration.compare($ago, Temporal.Duration.from({ seconds: 61 })) <
      0
    );
  });
  const offline = derived<typeof ago, boolean | nullish>(ago, ($ago) => {
    if (!$ago) return null;
    return (
      Temporal.Duration.compare($ago, Temporal.Duration.from({ seconds: 61 })) >
      0
    );
  });

  function latest(value: Temporal.Instant) {
    last.update(($prev: string | nullish) => {
      logger("latest", value?.toString(), $prev?.toString());
      if (!$prev) return value;
      if (!value) return value;
      return Temporal.Instant.compare(value, $prev) > 0 ? value : $prev;
    });
  }

  const position = writable<GeolocationPosition | null>(null);
  const property = writable<string | null>(null);
  const cmd = writable<Command | null>(null);

  let _cameras: Record<string, unknown> = {
    right: null,
    left: null,
  };
  const cameras = writable<Record<string, unknown>>(_cameras);
  const system = writable<unknown>(null);

  let events: EventSource;

  function eventing(id?: string) {
    if (events) events.close();

    // make more solid?

    last.set(null);
    position.set(null);
    property.set(null);
    cameras.set((_cameras = { right: null, left: null }));

    system.set(null);

    console.log("eventing, data cleared");

    if (!id) return;

    events = new EventSource(
      `https://lprd.communityboss.workers.dev/?id=${id}`
    );
    events.addEventListener("message", (e) => {
      // console.log("message", e);
      const data = JSON.parse(e.data);
      console.log("data=", data);
      if (!data) return;
      const tpv = data.position ?? data.tpv ?? data.gps ?? data.coordinates;
      if (data.cmd) cmd.set(data.cmd);
      if (tpv?.lat && tpv?.lon) {
        //console.log("tpv=", tpv);
        latest(Temporal.Instant.from(tpv.time));
        position.set({
          timestamp: new Date(tpv.time).getTime(),
          coords: {
            latitude: Number(tpv.lat.toFixed(5)),
            longitude: Number(tpv.lon.toFixed(5)),
            accuracy: tpv.eph,
            speed: tpv.speed,
            heading: tpv.track,
            altitude: tpv.alt,
            altitudeAccuracy: tpv.epv,
          },
        });
      }
      if (data.cameras) {
        cameras.set(
          (_cameras = {
            left: Object.values(data.cameras)[0],
            right: Object.values(data.cameras)[1],
          })
        );
      }

      if (data.property) {
        console.log("property", data.property);
        property.set(data.property?.id ?? data.property);
      } else if (data.property === false) {
        property.set(null);
      }
      if (data.system) {
        latest(Temporal.Instant.from(data.system.datetime));
        system.set({
          datetime: data.system.datetime,
          ...data.system.system,
        });
      }
    });
  }
</script>

<script lang="ts">
  export let id: Readable<ParamValue>;

  $: eventing($id);
</script>

<dl>
  <!-- <dt>Now</dt>
  <dd>{$now?.toString()}</dd> -->
  <dt>Device</dt>
  <dd>{$id}</dd>
  <dt>Status</dt>
  {#if null == $online}
    <dd>Checking&hellip;</dd>
  {:else if $offline}
    <dd>Offline</dd>
    <dd>Last seen {$ago?.toString()} ago</dd>
  {:else if $online}
    <dd>Online</dd>

    <dt>GPS</dt>
    <dd>
      <figure />
    </dd>
    <dt>Cameras</dt>
    <dd>{Object.values($cameras).length}</dd>
  {/if}
</dl>

import "temporal-polyfill/global";
import "./logging";
import { router, state, end, exit } from "./util/router";
import App from "./App.svelte";
import "./app.scss";

new App({ target: document.body });


router("/device/:id/status", state, end);
router("/device/:id", function (ctx) {
  router.redirect(`/devices/${ctx.params.id}/status`);
});

// index
router("*", state, end);

router.start();

import page from "page";
import { update as params } from "./params";
import isArray from "lodash-es/isArray";

// qs mechanics
page("*", function (ctx, next) {
  // don't run auth, we're running out of band
  const query = new URLSearchParams(ctx.querystring);
  ctx.query = [...query.keys()].reduce((result, k) => {
    var values = query.getAll(k);
    if (values?.length === 1) {
      result[k] = values[0];
    } else if (values?.length) {
      result[k] = values;
    }
    return result;
  }, {});
  //logger(ctx.query);

  next();
});

export default page;

export const router = page;
export const exit = page.exit;

export function state(ctx, next?) {
  params(Object.assign({}, ctx.params, ctx.query));
  if (!!next) next();
}


export function view(viewname: string) {
  return function (ctx: any, next: any) {
    ctx.params.view = viewname;
    next();
  };
}

export function route(url: string, history: boolean = true) {
  logger("route", url, "history=", history);
  if (!history) {
    logger("replace state", url, "history=", history);
    page.replace(url);
    //page.redirect(url);
  } else page(url);
}

export function end(ctx) {
  state(ctx);
}

// query does intelligent diffing to skip doing work if the query is the same
export function query(
  params: Record<string, any>,
  options?: { path?: string; history?: boolean }
) {
  logger("params=", ...Object.entries(params).flatMap(kv => kv));
  const qs = new URLSearchParams(location.search);
  let changed = false;
  for (const [k, v] of Object.entries(params)) {
    var prev = qs.getAll(k);
    if (null != v && isArray(v)) {
      // do the sets match?
      if (prev.length != v.length) {
        changed = true;
      } else {
        for (const v1 of v as any[]) {
          if (!prev.includes(v1)) {
            changed = true;
            break;
          }
        }
      }

      qs.delete(k);
      for (const valueItem of v) qs.append(k, valueItem);
    } else if (null != v) {
      // single value

      // multiple prev or different single means it's changed
      if (prev.length != 1 || prev[0] != v) {
        changed = true;
      }

      qs.set(k, v);
    } else {
      // only changed if there were previous values
      if (prev.length > 0) changed = true;
      qs.delete(k);
    }
  }
  logger("changed=", changed, "qs=", location.search, "->", qs.toString());
  if (!changed) return; // no change
  let q = qs.toString();
  if (q) q = "?" + q;
  route(
    (options?.path || location.pathname) + q,
    false !== options?.history
  );
}

import { max } from "lodash-es";
import { readable, type Readable } from "svelte/store";

type Instantable = Temporal.ZonedDateTime | Temporal.Instant;

const stores: Record<string, Readable<Temporal.ZonedDateTime>> = {};

function until(a: Instantable, b: Instantable): Temporal.Duration {
  if (a instanceof Temporal.ZonedDateTime) return until(a.toInstant(), b);
  if (b instanceof Temporal.ZonedDateTime) return until(a, b.toInstant());
  if (indefinite(b)) return maxDuration;
  return a.until(b);
}

export interface TemporalInterval<
  T =
  | Temporal.ZonedDateTime
  | Temporal.Instant
  | Temporal.PlainDateTime
  | Temporal.PlainDate
  | Temporal.PlainTime
> {
  readonly minimum: T;
  readonly maximum: T;
  //readonly duration: Temporal.Duration;
}


class TemporalIntervalBase<T = Instantable> {
  readonly minimum: T;
  readonly maximum: T;
  readonly duration: Temporal.Duration;

  constructor(minimum: T, maximum: T) {
    this.minimum = minimum;
    this.maximum = maximum;
    this.duration = until(minimum as Instantable, maximum as Instantable);
  }


  toString() {
    return `${this.minimum}/${this.maximum}`;
  }
}

export class TemporalPlainDateInterval
  implements TemporalInterval<Temporal.PlainDate> {
  readonly minimum: Temporal.PlainDate;
  readonly maximum: Temporal.PlainDate;
  constructor(minimum: Temporal.PlainDate, maximum: Temporal.PlainDate) {
    this.minimum = minimum;
    this.maximum = maximum;
  }

  static from(interval: string | TemporalPlainDateInterval | nullish): TemporalPlainDateInterval | null {
    if (!interval) return null;
    if (interval instanceof TemporalPlainDateInterval) return interval;
    if (typeof interval != "string") return null;
    const parts = interval.split("/");
    if (parts.length != 2) return null;
    return new TemporalPlainDateInterval(
      Temporal.PlainDate.from(parts[0]),
      Temporal.PlainDate.from(parts[1])
    );
  }
  contains(other: Temporal.PlainDate | TemporalPlainDateInterval, adjacentmin: boolean = true, adjacentmax = true): boolean {
    if (other instanceof Temporal.PlainDate) return this.contains(new TemporalPlainDateInterval(other, other), adjacentmin, adjacentmax);

    if (!adjacentmin && Temporal.PlainDate.compare(other.minimum, this.minimum) <= 0) return false;
    if (!adjacentmax && Temporal.PlainDate.compare(other.maximum, this.maximum) >= 0) return false;

    return Temporal.PlainDate.compare(other.minimum, this.minimum) >= 0 && Temporal.PlainDate.compare(other.maximum, this.maximum) <= 0;


  }
  overlaps(
    other: TemporalPlainDateInterval | null,
    adjacent: boolean = false
  ): boolean {
    if (!other) return false;
    if (adjacent)
      return (
        Temporal.PlainDate.compare(this.maximum, other.minimum) >= 0 &&
        Temporal.PlainDate.compare(this.minimum, other.maximum) <= 0
      );
    return (
      Temporal.PlainDate.compare(this.maximum, other.minimum) > 0 &&
      Temporal.PlainDate.compare(this.minimum, other.maximum) < 0
    );
  }
  // toZonedDateTimeInterval(timezone: string): TemporalZonedDateTimeInterval {
  //   return new TemporalZonedDateTimeInterval(
  //     this.minimum.toZonedDateTimeISO(timezone),
  //     this.maximum.toZonedDateTimeISO(timezone),
  //     timezone
  //   );
  // }
  starts(other: Temporal.PlainDate): boolean {
    return Temporal.PlainDate.compare(this.minimum, other) == 0;
  }
  static compare(a: TemporalPlainDateInterval | string | nullish, b: TemporalPlainDateInterval | string | nullish) {

    a = TemporalPlainDateInterval.from(a);
    b = TemporalPlainDateInterval.from(b);

    if (!a && b) return -1;
    if (a && !b) return 1;
    if (!a && !b) return 0;
    if (!a || !b) return 0; // cannot reach here

    if (Temporal.PlainDate.compare(a.minimum, b.minimum) == 0) {
      return Temporal.PlainDate.compare(a.maximum, b.maximum);
    }
    return Temporal.PlainDate.compare(a.minimum, b.minimum);
  }
}

export class TemporalInstantInterval
  extends TemporalIntervalBase<Temporal.Instant>
  implements TemporalInterval<Temporal.Instant> {
  constructor(minimum: Temporal.Instant, maximum: Temporal.Instant) {
    super(minimum, maximum);
  }

  static from(interval: string | TemporalInstantInterval | nullish): TemporalInstantInterval | null {
    if (!interval) return null;
    if (interval instanceof TemporalInstantInterval) return interval;
    if (typeof interval != "string") return null;
    const parts = interval.split("/");
    if (parts.length != 2) return null;
    return new TemporalInstantInterval(
      Temporal.Instant.from(parts[0]),
      parts[1] ? Temporal.Instant.from(parts[1]) : maxInstant
    );
  }
  contains(other: Temporal.Instant, adjacentmin: boolean = true, adjacentmax = true): boolean {
    if (adjacentmin && Temporal.Instant.compare(this.minimum, other) == 0) return true;
    if (adjacentmax && Temporal.Instant.compare(this.maximum, other) == 0) return true;
    return (
      Temporal.Instant.compare(this.minimum, other) < 0 &&
      Temporal.Instant.compare(this.maximum, other) > 0
    );

  }
  overlaps(
    other: TemporalInstantInterval | null,
    adjacent: boolean = false
  ): boolean {
    if (!other) return false;
    if (adjacent)
      return (
        Temporal.Instant.compare(this.maximum, other.minimum) >= 0 &&
        Temporal.Instant.compare(this.minimum, other.maximum) <= 0
      );
    return (
      Temporal.Instant.compare(this.maximum, other.minimum) > 0 &&
      Temporal.Instant.compare(this.minimum, other.maximum) < 0
    );
  }
  toZonedDateTimeInterval(timezone: string): TemporalZonedDateTimeInterval {
    return new TemporalZonedDateTimeInterval(
      this.minimum.toZonedDateTimeISO(timezone),
      this.maximum.toZonedDateTimeISO(timezone),
      timezone
    );
  }
  static compare(a: TemporalInstantInterval | string | nullish, b: TemporalInstantInterval | string | nullish) {

    a = TemporalInstantInterval.from(a);
    b = TemporalInstantInterval.from(b);

    if (!a && b) return -1;
    if (a && !b) return 1;
    if (!a && !b) return 0;
    if (!a || !b) return 0; // cannot reach here

    if (Temporal.Instant.compare(a.minimum, b.minimum) == 0) {
      return Temporal.Instant.compare(a.maximum, b.maximum);
    }
    return Temporal.Instant.compare(a.minimum, b.minimum);
  }
}

export class TemporalZonedDateTimeInterval
  extends TemporalIntervalBase<Temporal.ZonedDateTime>
  implements TemporalInterval<Temporal.ZonedDateTime> {
  readonly timezone: Temporal.TimeZoneLike;
  constructor(
    minimum: Temporal.Instant | Temporal.ZonedDateTime,
    maximum: Temporal.Instant | Temporal.ZonedDateTime,
    timezone: Temporal.TimeZoneLike
  ) {
    if (
      !timezone &&
      minimum instanceof Temporal.ZonedDateTime &&
      maximum instanceof Temporal.ZonedDateTime &&
      (minimum as Temporal.ZonedDateTime).timeZoneId !=
      (maximum as Temporal.ZonedDateTime).timeZoneId
    ) {
      throw new Error("Same timezone required");
    }
    super(
      minimum instanceof Temporal.ZonedDateTime
        ? timezone
          ? minimum.withTimeZone(timezone)
          : minimum
        : minimum.toZonedDateTimeISO(timezone),
      maximum instanceof Temporal.ZonedDateTime
        ? timezone
          ? maximum.withTimeZone(timezone)
          : maximum
        : maximum.toZonedDateTimeISO(timezone)
    );
    this.timezone = timezone;
  }
  toString(
    opts: Temporal.ZonedDateTimeToStringOptions = {
      timeZoneName: "never",
      calendarName: "never",
    }
  ): string {
    return `${this.minimum.toString(opts)}/${this.maximum.toString(opts)}`;
  }
  toInstant(): TemporalInstantInterval {
    return new TemporalInstantInterval(
      this.minimum.toInstant(),
      this.maximum.toInstant()
    );
  }
  contains(other: Temporal.ZonedDateTime | Temporal.ZonedDateTimeLike | TemporalZonedDateTimeInterval, adjacentmin: boolean = true, adjacentmax = true): boolean {
    if (other instanceof TemporalZonedDateTimeInterval) return this.contains(other.minimum, adjacentmin, adjacentmax) && this.contains(other.maximum, adjacentmin, adjacentmax);

    if (!adjacentmin && Temporal.ZonedDateTime.compare(other, this.minimum) <= 0) return false;
    if (!adjacentmax && Temporal.ZonedDateTime.compare(other, this.maximum) >= 0) return false;

    return Temporal.ZonedDateTime.compare(other, this.minimum) >= 0 && Temporal.PlainDate.compare(other, this.maximum) <= 0;


  }
  overlaps(
    other: TemporalZonedDateTimeInterval | TemporalInstantInterval | Temporal.PlainDate | null,
    adjacent: boolean = false
  ): boolean {
    if (!other) return false;
    if (other instanceof TemporalInstantInterval)
      return other.overlaps(this.toInstant(), adjacent);
    if (other instanceof Temporal.PlainDate) return Temporal.PlainDate.compare(other, this.minimum.toPlainDate()) >= 0 && Temporal.PlainDate.compare(other, this.maximum.toPlainDate()) <= 0;
    if (adjacent)
      return (
        Temporal.ZonedDateTime.compare(this.maximum, other.minimum) >= 0 &&
        Temporal.ZonedDateTime.compare(this.minimum, other.maximum) <= 0
      );
    return (
      Temporal.ZonedDateTime.compare(this.maximum, other.minimum) > 0 &&
      Temporal.ZonedDateTime.compare(this.minimum, other.maximum) < 0
    );
  }
  static compare(a: TemporalZonedDateTimeInterval | TemporalInstantInterval | string | nullish, b: TemporalZonedDateTimeInterval | TemporalInstantInterval | string | nullish) {

    // resolve to lowest common denominator
    // if (typeof a == "string") a = TemporalInstantInterval.from(a);
    // if (typeof b == "string") b = TemporalInstantInterval.from(b);

    if (a instanceof TemporalZonedDateTimeInterval) a = a.toInstant();
    if (b instanceof TemporalZonedDateTimeInterval) b = b.toInstant();

    return TemporalInstantInterval.compare(a, b);




    // if (Temporal.Instant.compare(a.minimum, b.minimum) == 0) {
    //   return Temporal.ZonedDateTime.compare(a.maximum, b.maximum);
    // }
    // return Temporal.ZonedDateTime.compare(a.minimum, b.minimum);
  }
  static from(interval: string | nullish, timezone: string): TemporalZonedDateTimeInterval | null {
    if (!interval) return null;
    if (typeof interval != "string") return null;
    const parts = interval.split("/");
    if (parts.length != 2) return null;
    return new TemporalZonedDateTimeInterval(
      Temporal.Instant.from(parts[0]),
      parts[1] ? Temporal.Instant.from(parts[1]) : maxInstant,
      timezone);
  }
}

export function instant(
  interval: string | Temporal.DurationLike,
  timezone?: string
): Readable<Temporal.ZonedDateTime> {
  //if (!timezone) timezone = Temporal.Now.timeZoneId();
  const ms = Temporal.Duration.from(interval).total({ unit: "milliseconds" });
  const key = `${ms}-${timezone || ""}`;
  const now = Temporal.Now.zonedDateTimeISO(timezone);
  return (stores[key] ??= readable(
    now,
    (set) => {
      set(now);
      const i = setInterval(
        () => set(Temporal.Now.zonedDateTimeISO(timezone)),
        ms
      );
      return () => clearInterval(i);
    }
  ));
}

export function before(first: Temporal.Instant, second: Temporal.Instant) {
  return Temporal.Instant.compare(first, second) === -1;
}

export function after(first: Temporal.Instant, second: Temporal.Instant) {
  return Temporal.Instant.compare(first, second) === 1;
}

export const zero = new Temporal.Duration(0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
export const second = Temporal.Duration.from({ seconds: 1 });
export const minute = Temporal.Duration.from({ minutes: 1 });
export const hour = Temporal.Duration.from({ hours: 1 });

export const midnight = new Temporal.PlainTime(0, 0, 0, 0, 0, 0);
export const minInstant = new Temporal.Instant(-2208988800000000000n);
export const maxInstant = new Temporal.Instant(2208988800000000000n);
export const maxDuration = maxInstant.since(new Temporal.Instant(0n));

const timeformat: Intl.DateTimeFormatOptions = {
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  //timeZone: "UTC",
  //second: "numeric",
  //timeZoneName: "short",
};
const basetimeformatter = new Intl.DateTimeFormat("en-US", timeformat);
const shortdateformat: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  //timeZone: "UTC"
};
const basedateformatter = new Intl.DateTimeFormat("en-US", shortdateformat);

const timeByTZ: Record<string, Intl.DateTimeFormat> = {};

export function time(value: Temporal.ZonedDateTime | Temporal.PlainTime): string {
  if (!value) return "";
  if (value instanceof Temporal.ZonedDateTime) return time(value.toPlainTime());

  //new Date(Temporal.Now.plainDateISO().toPlainDateTime(value).toString())


  //const tz = value.timeZoneId;

  // const formatter = (timeByTZ[tz] ??= new Intl.DateTimeFormat("en-US", {
  //   ...basetimeformat,
  //   timeZone: tz,
  // }));

  // this conversion to date is assuming parsed as local time
  return basetimeformatter.format(new Date(2000, 0, 1, value.hour, value.minute, value.second, value.millisecond));

  // const parts = formatter.formatToParts(value.epochMilliseconds).reduce(
  //   (result, part) => {
  //     if (part.type === "literal") return result;
  //     result[part.type] = part.value;
  //     return result;
  //   },
  //   {} as Record<string, string>
  // );

  //logger(date.toString(), parts, date.getTimeZone());

  //return `${parts.hour}:${parts.minute} ${parts.dayPeriod}`;

}

export function dateparts(value: Temporal.ZonedDateTime | Temporal.PlainDateTime | Temporal.PlainDate, formatter: Intl.DateTimeFormat = basedateformatter): Record<Intl.DateTimeFormatPartTypes, string> {
  if (value instanceof Temporal.ZonedDateTime) return dateparts(value.toPlainDate());
  if (value instanceof Temporal.PlainDateTime) return dateparts(value.toPlainDate());

  return formatter.formatToParts(new Date(value.year, value.month - 1, value.day)).reduce(
    (result, part) => {
      if (part.type === "literal") return result;
      result[part.type] = part.value;
      return result;
    },
    {} as Record<Intl.DateTimeFormatPartTypes, string>
  );
}

export function date(value: Temporal.ZonedDateTime | Temporal.PlainDateTime | Temporal.PlainDate, yearIfSame: boolean = true): string {
  if (!value) return "";
  // if (value instanceof Temporal.ZonedDateTime) return date(value.toPlainDate(), yearIfSame);
  // if (value instanceof Temporal.PlainDateTime) return date(value.toPlainDate(), yearIfSame);

  //new Date(Temporal.Now.plainDateISO().toPlainDateTime(value).toString())


  //const tz = value.timeZoneId;

  // const formatter = (timeByTZ[tz] ??= new Intl.DateTimeFormat("en-US", {
  //   ...basetimeformat,
  //   timeZone: tz,
  // }));

  //return base.format(new Date(value.toString()));

  // this conversion to date is assuming parsed as local time
  // const parts = basedateformatter.formatToParts(new Date(value.year, value.month - 1, value.day)).reduce(
  //   (result, part) => {
  //     if (part.type === "literal") return result;
  //     result[part.type] = part.value;
  //     return result;
  //   },
  //   {} as Record<string, string>
  // );

  const parts = dateparts(value);


  const sameYear = value.year === Temporal.Now.plainDateISO().year;

  logger(parts, "sameYear", sameYear, yearIfSame);

  return yearIfSame || !sameYear ? `${parts.weekday} ${parts.month} ${parts.day} ${parts.year}` : `${parts.weekday} ${parts.month} ${parts.day}`;

}

const baseformat: Intl.DateTimeFormatOptions = {
  ...timeformat,
  ...shortdateformat,
  timeZoneName: "short",
};

const formattersByTZ: Record<string, Intl.DateTimeFormat> = {};

function defaultformattertz(values: Record<"hour" | "minute" | "dayPeriod" | "weekday" | "month" | "day" | "year" | "timeZoneName", string>) {
  return `${values.hour}:${values.minute} ${values.dayPeriod} ${values.weekday} ${values.month} ${values.day} ${values.year} ${values.timeZoneName}`;
}

function defaultformatter(values: Record<"hour" | "minute" | "dayPeriod" | "weekday" | "month" | "day" | "year" | "timeZoneName", string>) {
  return `${values.hour}:${values.minute} ${values.dayPeriod} ${values.weekday} ${values.month} ${values.day} ${values.year}`;
}


export function datetime(date: Temporal.ZonedDateTime | Temporal.Instant | string | nullish, timezone: boolean | string = true, toString: typeof defaultformatter = defaultformattertz) {
  if (!date) return "";

  if (typeof date == "string") date = Temporal.Instant.from(date);


  // we passed a timezone by id
  if (typeof timezone == "string" && date instanceof Temporal.Instant) return datetime(date.toZonedDateTimeISO(timezone), true, toString);

  if (date instanceof Temporal.Instant) return datetime(date.toZonedDateTimeISO(Temporal.Now.timeZoneId()), timezone, toString);

  // check for no timezone flag
  if (!timezone && toString === defaultformattertz) return datetime(date, timezone, defaultformatter);

  const tz = date.timeZoneId;

  const formatter = (formattersByTZ[tz] ??= new Intl.DateTimeFormat("en-US", {
    ...baseformat,
    timeZone: tz,
  }));

  const parts = formatter.formatToParts(date.epochMilliseconds).reduce(
    (result, part) => {
      if (part.type === "literal") return result;
      result[part.type] = part.value;
      return result;
    },
    {} as Record<string, string>
  );

  //logger(date.toString(), parts, date.getTimeZone());

  return toString(parts); //`${parts.hour}:${parts.minute} ${parts.dayPeriod} ${parts.weekday} ${parts.month} ${parts.day} ${parts.year} ${parts.timeZoneName}`;
}

export function indefinite(value: Temporal.ZonedDateTime | Temporal.Instant | Temporal.Duration): boolean {
  if (value instanceof Temporal.Duration) {
    logger("indefinite", value.toString(), maxDuration.toString(), Temporal.Duration.compare(value, maxDuration) >= 0);
    return Temporal.Duration.compare(value, maxDuration) >= 0;
  }
  if (value instanceof Temporal.ZonedDateTime) return indefinite(value.toInstant());
  //if (value instanceof Temporal.Instant) {
  return Temporal.Instant.compare(value, maxInstant) >= 0 || Temporal.Instant.compare(value, minInstant) <= 0;
  //}
  //return Temporal.Instant.compare(value.toInstant(), maxInstant) >= 0 || Temporal.Instant.compare(value.toInstant(), minInstant) <= 0;
}


export function iso(
  value:
    | Temporal.ZonedDateTime
    | Temporal.Instant
    | Temporal.PlainDateTime
    | Temporal.PlainDate
    | Temporal.PlainTime
    | Temporal.Duration
    | string
    | null
    | undefined
): string | nullish {
  if (!value) return;
  if (typeof value == "string") return value;
  if (
    value instanceof Temporal.Instant &&
    Temporal.Instant.compare(value, maxInstant) >= 0
  )
    return "";
  if (value instanceof Temporal.Duration && indefinite(value)) return "";
  if (
    value instanceof Temporal.ZonedDateTime &&
    indefinite(value)
  )
    return "";
  if (
    value instanceof Temporal.Instant &&
    indefinite(value)
  )
    return "";
  return value.toString({
    timeZoneName: "never",
    calendarName: "never",
    //smallestUnit: "millisecond",
  });
}

export function dates(
  min: Temporal.PlainDate,
  max: Temporal.PlainDate
): Temporal.PlainDate[] {
  const items = [];
  for (
    var i = min;
    Temporal.PlainDate.compare(i, max) <= 0;
    i = i.add({ days: 1 })
  ) {
    items.push(i);
  }
  return items;
}

//export const midnight = Temporal.PlainTime.from("00:00:00");

export function times(
  min: Temporal.PlainTime = Temporal.PlainTime.from("00:00:00"),
  max: Temporal.PlainTime = Temporal.PlainTime.from("23:59:59"),
  increment: Temporal.Duration = Temporal.Duration.from("PT15M")
): Temporal.PlainTime[] {
  logger(
    "times eval",
    min.toString(),
    max.toString(),
    increment.toString()
  );
  if (Temporal.PlainTime.compare(min, max) == 0) return [min];

  const items = [];
  if (Temporal.PlainTime.compare(min, max) > 0) {
    // crosses midnight

    // go from min to midnight
    for (
      var i = min;
      Temporal.PlainTime.compare(i, midnight) > 0 &&
      Temporal.PlainTime.compare(i, Temporal.PlainTime.from("23:59:59")) < 0;
      i = i.add(increment)
    ) {
      items.push(i);
    }

    //items.push(midnight);

    // go from midnight to max
    for (
      var i = Temporal.PlainTime.from("00:00");
      Temporal.PlainTime.compare(i, max) <= 0;
      i = i.add(increment)
    ) {
      items.push(i);
    }
  } else {
    // go from min to max
    // handle midnight
    if (Temporal.PlainTime.compare(min, midnight) == 0) {
      items.push(min);
      min = min.add(increment);
    }
    for (
      var i = min;
      Temporal.PlainTime.compare(i, midnight) > 0 &&
      Temporal.PlainTime.compare(i, max) <= 0; // greater than midnight less than max
      i = i.add(increment)
    ) {
      items.push(i);
    }
  }

  return items;
}

export function validateInstant(
  $value: Temporal.Instant | Temporal.ZonedDateTime,
  $min: Temporal.Instant | Temporal.ZonedDateTime,
  $max: Temporal.Instant | Temporal.ZonedDateTime
): boolean {
  if ($value instanceof Temporal.ZonedDateTime) $value = $value.toInstant();
  if ($min instanceof Temporal.ZonedDateTime) $min = $min.toInstant();
  if ($max instanceof Temporal.ZonedDateTime) $max = $max.toInstant();
  return (
    Temporal.Instant.compare($value, $min) >= 0 &&
    Temporal.Instant.compare($value, $max) <= 0
  );
}

export function validateDuration(
  $value: Temporal.Duration,
  $min: Temporal.Duration,
  $max: Temporal.Duration
): boolean {
  return (
    Temporal.Duration.compare($value, $min) >= 0 &&
    Temporal.Duration.compare($value, $max) <= 0
  );
}

export function durations(
  min: Temporal.Duration,
  max: Temporal.Duration,
  increment: Temporal.Duration
): Temporal.Duration[] {
  const items = [];
  for (
    var i = min.total("milliseconds");
    i <= max.total("milliseconds");
    i += increment.total("milliseconds")
  ) {
    items.push(Temporal.Duration.from({ milliseconds: i }));
  }
  return items;
}

export function validateTime(
  $value: Temporal.PlainTime,
  $min: Temporal.PlainTime,
  $max: Temporal.PlainTime
) {
  // logger(
  //   "validateTime",
  //   $value.toString(),
  //   $min.toString(),
  //   $max.toString()
  // );
  if (Temporal.PlainTime.compare($min, $max) < 0) {
    // normal in-day
    if (Temporal.PlainTime.compare($value, $min) < 0) return false;
    if (Temporal.PlainTime.compare($value, $max) > 0) return false;
  } else {
    // crosses midnight
    if (
      Temporal.PlainTime.compare($value, $min) < 0 &&
      Temporal.PlainTime.compare($value, $max) > 0
    )
      return false;
  }

  return true;
}

export function parseInterval(
  interval: string | nullish
): [Temporal.Instant, Temporal.Instant] | null {
  if (!interval) return null;

  const parts = interval.split("/");
  if (parts.length != 2) return null;

  return [
    (parts[0] && Temporal.Instant.from(parts[0])) || minInstant,
    (parts[1] && Temporal.Instant.from(parts[1])) || maxInstant,
  ];
}

export function overlappingIntervals(
  a: [Temporal.Instant, Temporal.Instant] | nullish,
  b: [Temporal.Instant, Temporal.Instant] | nullish,
  adjacent = false
) {
  //logger("overlappingIntervals", a, b, adjacent);
  if (!a || !b) return false;
  // ensure sorted
  a = a.sort(Temporal.Instant.compare);
  b = b.sort(Temporal.Instant.compare);

  if (!adjacent && a[1].since(a[0]).total({ unit: "milliseconds" }) == 0)
    return false;
  if (!adjacent && b[1].since(b[0]).total({ unit: "milliseconds" }) == 0)
    return false;

  if (Temporal.Instant.compare(a[1], b[0]) < (adjacent ? 0 : 1)) return false;
  if (Temporal.Instant.compare(a[0], b[1]) > (adjacent ? 0 : 1)) return false;

  // if (before(a[1], b[0])) return false;
  // if (after(a[0], b[1])) return false;
  return true;
}

export function availableStartDates(
  intervals: TemporalZonedDateTimeInterval[]
): Temporal.PlainDate[] {
  return Object.values(
    intervals.reduce(
      (
        result,
        interval
      ) => {
        if (interval?.minimum) {
          var date = interval.minimum.toPlainDate();
          result[date.toString()] ??= date;
        }

        return result;
      },
      {} as Record<string, Temporal.PlainDate>
    )
  ).sort(Temporal.PlainDate.compare);
}
export function availableStartTimes(
  intervals: TemporalZonedDateTimeInterval[],
  relativeToStartDate?: Temporal.PlainDate
): Temporal.PlainTime[] {
  return Object.values(
    intervals.reduce(
      (
        result: Record<string, Temporal.PlainTime>,
        interval: TemporalInterval<Temporal.ZonedDateTime>
      ) => {
        if (interval?.minimum) {
          // date must be the same as relativeToDate
          if (
            relativeToStartDate &&
            Temporal.PlainDate.compare(
              interval.minimum.toPlainDate(),
              relativeToStartDate
            ) != 0
          )
            return result;
          var time = interval.minimum.toPlainTime();
          result[time.toString()] ??= time;
        }

        return result;
      },
      {}
    )
  ).sort(Temporal.PlainTime.compare);
}

export function availableEndDates(
  intervals: TemporalZonedDateTimeInterval[],
  relativeToStartDate?: Temporal.PlainDate,
  relativeToStartTime?: Temporal.PlainTime
): Temporal.PlainDate[] {
  const items = Object.values(
    intervals.reduce(
      (
        result: Record<string, Temporal.PlainDate>,
        interval: TemporalInterval<Temporal.ZonedDateTime>
      ) => {
        if (interval?.minimum) {
          // date must be the same as relativeToDate
          if (
            relativeToStartDate &&
            Temporal.PlainDate.compare(
              interval.minimum.toPlainDate(),
              relativeToStartDate
            ) != 0
          )
            return result;
          if (
            relativeToStartTime &&
            Temporal.PlainTime.compare(
              interval.minimum.toPlainTime(),
              relativeToStartTime
            ) != 0
          )
            return result;
        }
        if (interval?.maximum) {
          var date = interval.maximum.toPlainDate();
          result[date.toString()] ??= date;
        }

        return result;
      },
      {}
    )
  ).sort(Temporal.PlainDate.compare);
  return items;
}

export function availableEndTimes(
  intervals: TemporalZonedDateTimeInterval[],
  relativeToStartDate?: Temporal.PlainDate,
  relativeToStartTime?: Temporal.PlainTime,
  relativeToEndDate?: Temporal.PlainDate
): Temporal.PlainTime[] {
  // logger(
  //   "availableEndTimes",
  //   relativeToStartDate?.toString(),
  //   relativeToStartTime?.toString(),
  //   relativeToEndDate?.toString()
  // );

  const items = Object.values(
    intervals.reduce(
      (
        result: Record<string, Temporal.PlainTime>,
        interval: TemporalInterval<Temporal.ZonedDateTime>
      ) => {
        if (interval?.minimum) {
          // date must be the same as relativeToDate
          if (
            relativeToStartDate &&
            Temporal.PlainDate.compare(
              interval.minimum.toPlainDate(),
              relativeToStartDate
            ) != 0
          )
            return result;
          if (
            relativeToStartTime &&
            Temporal.PlainTime.compare(
              interval.minimum.toPlainTime(),
              relativeToStartTime
            ) != 0
          )
            return result;
        }
        if (interval?.maximum) {
          if (
            relativeToEndDate &&
            Temporal.PlainDate.compare(
              interval.maximum.toPlainDate(),
              relativeToEndDate
            ) != 0
          )
            return result;
          var time = interval.maximum.toPlainTime();
          result[time.toString()] ??= time;
        }

        return result;
      },
      {}
    )
  ).sort(Temporal.PlainTime.compare);
  logger(
    "availableEndTimes=",
    items.map((i) => i.toString())
  );
  return items;
}


export class PlainDayTime {
  day: number;
  time: Temporal.PlainTime;
  constructor(day: number, time: Temporal.PlainTime) {
    this.day = day;
    this.time = time;
  }
  static from(s: string): PlainDayTime {
    const [day, time] = s.split("T");
    return new PlainDayTime(+day, Temporal.PlainTime.from(time));
  }
  addDays(days: number): PlainDayTime {
    if (days > 7) return new PlainDayTime(this.day + (days % 7), this.time); // man gpt got this one
    if (this.day + days > 7)
      return new PlainDayTime(this.day + days - 7, this.time);
    return new PlainDayTime(this.day + days, this.time);
  }
  static compare(a: PlainDayTime, b: PlainDayTime): number {
    if (a.day > b.day) return 1;
    if (a.day < b.day) return -1;
    return Temporal.PlainTime.compare(a.time, b.time);
  }
  toString(): string {
    return `${this.day}T${this.time}`;
  }
}

export class PlainDayTimeInterval {
  start: PlainDayTime;
  end: PlainDayTime;
  constructor(start: PlainDayTime, end: PlainDayTime) {
    this.start = start;
    this.end = end;
  }
  static from(s: string): PlainDayTimeInterval {
    const [start, end] = s.split("/");
    return new PlainDayTimeInterval(
      PlainDayTime.from(start),
      PlainDayTime.from(end)
    );
  }
  toString(): string {
    return `${this.start}/${this.end}`;
  }

  duration(): Temporal.Duration {
    if (this.wrapsWeek()) {
      // two parts
      const days = 6 - this.start.day + this.end.day;
      return Temporal.Duration.from({ days })
        .add(this.start.time.until(midnight))
        .add(this.end.time.since(midnight));
    }
    const days = this.end.day - this.start.day;
    return Temporal.Duration.from({ days })
      .add(this.start.time.until(midnight))
      .add(this.end.time.since(midnight));

    //return null;
  }
  contains(t: PlainDayTime | PlainDayTimeInterval): boolean {
    if (t instanceof PlainDayTimeInterval) {
      // logger(
      //   "contains interval",
      //   this.toString(),
      //   t.toString(),
      //   this.contains(t.start) && this.contains(t.end)
      // );
      return this.contains(t.start) && this.contains(t.end);
    }
    //   logger(
    //     "contains",
    //     this.start.toString(),
    //     t.toString(),
    //     this.end.toString(),
    //     PlainDayTime.compare(this.start, t),
    //     PlainDayTime.compare(this.end, t),
    //     PlainDayTime.compare(this.start, t) <= 0 &&
    //       PlainDayTime.compare(this.end, t) > 0
    //   );
    if (this.wrapsWeek()) {
      return (
        PlainDayTime.compare(this.start, t) <= 0 ||
        PlainDayTime.compare(this.end, t) > 0
      );
    }
    return (
      PlainDayTime.compare(this.start, t) <= 0 &&
      PlainDayTime.compare(this.end, t) > 0
    );
  }
  // overlaps(other: PlainDayTimeInterval): boolean {
  //   return (
  //     this.contains(other.start) ||
  //     this.contains(other.end) ||
  //     other.contains(this.start) ||
  //     other.contains(this.end)
  //   );
  // }
  wrapsWeek(): boolean {
    return this.start.day > this.end.day;
  }
}
export function* months(
  a: Temporal.PlainYearMonth,
  b: Temporal.PlainYearMonth
): Generator<Temporal.PlainYearMonth, void, any> {
  while (Temporal.PlainYearMonth.compare(a, b) <= 0) {
    yield a;
    a = a.add({ months: 1 });
  }
}
export function* days(
  month: Temporal.PlainYearMonth
): Generator<Temporal.PlainDate, void, any> {
  for (var day = 1; day <= month.daysInMonth; day++) {
    yield month.toPlainDate({ day });
  }
}
export function* daysparts(
  month: Temporal.PlainYearMonth,
  formatter: Intl.DateTimeFormat = basedateformatter
): Generator<
  [Temporal.PlainDate, Record<Intl.DateTimeFormatPartTypes, string>],
  void,
  any
> {
  for (const day of days(month)) {
    yield [day, dateparts(day, formatter)];
  }
}
export function plaindate(
  value:
    | Temporal.ZonedDateTime
    | Temporal.PlainDate
    | Temporal.PlainDateLike
    | string
) {
  if (value instanceof Temporal.PlainDate) return value;
  if (value instanceof Temporal.ZonedDateTime) return value.toPlainDate();
  return Temporal.PlainDate.from(value);
}
export function plainyearmonth(
  value:
    | Temporal.ZonedDateTime
    | Temporal.PlainDate
    | Temporal.PlainYearMonth
    | Temporal.PlainYearMonthLike
    | string
): Temporal.PlainYearMonth {
  if (value instanceof Temporal.PlainYearMonth) return value;
  if (value instanceof Temporal.ZonedDateTime)
    return value.toPlainYearMonth();
  if (value instanceof Temporal.PlainDate) return value.toPlainYearMonth();
  return Temporal.PlainYearMonth.from(value);
}
<script lang="ts" context="module">
  const id = param("id");
</script>

<script lang="ts">
  import LprdStatusSection from "$sections/LprdStatusSection.svelte";
  import { param } from "$utils/params";
</script>

<svelte:head>
  <title>LPRD</title>
</svelte:head>
<LprdStatusSection {id} />
